<template>
  <div :class="classes">
    <div class="giga-card">
      <div class="giga-card_wrap">
        <div class="giga-card_content">
          <div class="giga-card_profile">
            <div class="giga-card_profile_thumb">
              <div class="profile_thumb">
                <img src="@/assets/images/contact-mask.svg" />
              </div>
            </div>
            <div class="giga-card_profile_infos">
              <ul class="giga-card_profile_infos_list">
                <li class="giga-card_profile_info_item">
                  <div class="giga-card_profile_info_content">
                    <h5 class="giga-card_profile_name">{{ lastname }} <strong>{{ firstname }}</strong></h5>
                    <span class="giga-card_profile_function">{{profile}}</span>
                  </div>
                </li>
                <li class="giga-card_profile_info_item">
                  <div class="giga-card_profile_info_content">
                    <a class="giga-card_profile_email">{{email}}</a>
                  </div>
                </li>
                <li class="giga-card_profile_info_item">
                  <div class="giga-card_profile_info_content">
                    <span v-if="phone !== '0'" class="giga-card_profile_tel">{{phone}}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <b-button
            class="giga-card_profile_send-button"
            :href="'mailto:' + email"
            >Envoyer un E-mail</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "contactCard",
  props: {
    lastname: {
      type: String,
    },
    firstname: {
      type: String,
    },
    profile: {
      type: String,
    },
    email: {
      type: String,
    },
    phone: {
      type: Number,
    },
    sendEmail: {
      type: Boolean,
    },
    classes:{
     type: String
    }
  },
};
</script>